import * as React from 'react';

function dontSetState(_arg1: any, _arg2: any): void {
    return;
}

interface ISafelySetState<P, S> {
    setState<K extends keyof S>(
        state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null),
        callback?: () => void
    ): void;
}

interface IRedirect {
    redirect?: JSX.Element;
}

export class SafePureComponent<P = unknown, S = unknown, SS = any> extends React.PureComponent<P, S, SS> {
    protected safe: ISafelySetState<P, S> = { setState: dontSetState };

    public componentDidMount() {
        if (super.componentDidMount) { super.componentDidMount(); }
        this.safe.setState = this.setState.bind(this);
    }

    public componentWillUnmount() {
        if (super.componentWillUnmount) { super.componentWillUnmount(); }
        this.safe.setState = dontSetState;
    }

    public get isAlive(): boolean {
        return this.safe.setState === dontSetState;
    }

    public redirect(e: JSX.Element) {
        this.safe.setState({ redirect: e } as S & IRedirect);
    }


    public componentDidUpdate(_prevProps: any, _prevState: any) {
        const currRedirect = this.redirectElement;
        if (currRedirect) {
            this.safe.setState({ redirect: undefined } as S & IRedirect);
        }
    }

    public get redirectElement() {
        return this.state && (this.state as S & IRedirect).redirect;
    }
}
