import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import './App.css';
import { ImageGallery } from './Components/ImageGallery';
import { images } from './descs';
import { SafePureComponent } from './utils/SafePureComponent';

const css = mergeStyleSets({
});

class App extends SafePureComponent<unknown, unknown> {
    render() {
        return (
            <div>
                <h2>CSC Grounds</h2>
                <p>Thank you for taking the time to look over the unidentified property on the grounds</p>
                <p>The rules state that all property should be clearly labelled, and to be honest,
                    we don't know who a lot of these items belong to.</p>
                <p>The club has the right to dispose of unclaimed property after three months, 
                and there are currently a pile of road trailers, etc, that don't seem to have owners.
                To inadvertent disposal, please take a moment to look for your property in the galleries below, 
                    and if you have spotted anything of yours, please use the links to send the rear commodore a message, in addition
                    to labelling your property once COVID 19 restrictions are lifted, or while you're checking on your property at the club.
                </p>

                <p>Again, thank you for your cooperation. <br />On behalf of the grounds committee...<br />Tony Wieser<br />Rear Commodore<br />Cam Sailing Club</p>
                <hr />
                <ImageGallery images={images.largeTrailers} label="In the car park and near the shed" />
                <ImageGallery images={images.underShed} label="Under the sheds" />

                <ImageGallery images={images.dinghyPark} label="In the dinghy park" />

                <h2>Spring clearout</h2>
                
                <p>In addition, in the spring when the boats come back out of the sail sheds we will be taking out all of the sails and moving them to another shed.  You can move your sails back into the main shed once you've lablled them.
                    Any sails which have not been claimed be deemed abandoned, and put up for sale first within the club, or sold online.</p>

                <p>Again, thanks for your cooperation.  We're trying to improve the sail storage, and find out just how many sails we need to store.</p>
            </div>
            
        );
    }
}

export default App;
