export const images: {
  [ key : string ]
  : string[]
} = {
  largeTrailers: [
    "135220.jpg",
    "135227.jpg",
    "135234.jpg",
    "135304.jpg",
    "135329.jpg",
    "135342.jpg",
    "135350.jpg",
    "135356.jpg",
    "135409.jpg",
    "135426.jpg",
    "135510.jpg",
    "135516.jpg",
    "135526.jpg",
    "135531.jpg",
    "135538.jpg",
    "135549.jpg",
    "135559.jpg",
    "135607.jpg",
    "140206.jpg",
    "140218.jpg",
    "140227.jpg",
    "140247.jpg",
    "2141737.jpg",
    "2141742.jpg",
    "2141754.jpg",
  ],
"underShed": [
  "140257.jpg",
  "140305.jpg",
  "140343.jpg",
  "140401.jpg",
  "140444.jpg",
  "140507.jpg",
  "140524.jpg",
  "140535.jpg",
  "140549.jpg",
  "140552.jpg",
  "140607.jpg",
  "140633.jpg",
  ], dinghyPark: [
  "140649.jpg",
  "140705.jpg",
  "140711.jpg",
  "140716.jpg",
  "140727.jpg",
  "140734.jpg",
  "140741.jpg",
  "140748.jpg",
  "140752.jpg",
  "140759.jpg",
  "140807.jpg",
  "140815.jpg",
  "140832.jpg",
  "140854.jpg",
  "140859.jpg",
  "140905.jpg",
  "140910.jpg",
  "140917.jpg",
  "140925.jpg",
  "140944.jpg",
  "140952.jpg",
  "141003.jpg",
  "141012.jpg",
  "141021.jpg",
  "141035.jpg",
  "141047.jpg",
  "141051.jpg",
  "141056.jpg",
  "141103.jpg",
  "141114.jpg",
  "141119.jpg",
  "141128.jpg",
  "141451.jpg",
  "141501.jpg",
  "141512.jpg",
  "141536.jpg",
] };
