import { DefaultButton, Label, Link, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { SafePureComponent } from '../utils/SafePureComponent';

interface ImageGalleryProps {
    label: string;
    images: string[];

}

const css = mergeStyleSets({
    container: {
        selectors: {
            "h2": {
                textAlign: "center"
            }
        }
    },
    nav: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    },
    gallery: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center"
    },
    galleryItem: {
        flex: "none",
        textAlign: "center",
    },
    img: {
    }
});

const initialState = {
    currentImage: 0
};

export class ImageGallery extends SafePureComponent<ImageGalleryProps, typeof initialState>
{
    public state = initialState;

    private prev = () => {
        this.safe.setState({currentImage: this.state.currentImage-1})
    }

    private next = () => {
        this.safe.setState({ currentImage: this.state.currentImage + 1 })
    }


    public render() {
        const { label, images } = this.props;
        const { currentImage } = this.state;
        const currentImageName = images[currentImage];
        return <div className={css.container} >
            <h2>{label}</h2>
            <div className={css.gallery}>
                <div className={css.galleryItem} >
                    <img className={css.img} src={`${process.env.PUBLIC_URL}/images/${currentImageName}`} />
                </div>
                <Link className={css.galleryItem} href={`mailto:rearcomm@camsailingclub.org.uk?body=Enter%20the%20details%20of%20what's%20yours%20in%20${currentImageName}%20below%0D%0A&subject=That's%20mine!%20Image:%20${currentImageName}`}>
                    Tell us there's something of yours in photo {currentImageName}...
                    </Link>

                <div className={`${css.galleryItem } ${css.nav}`} >
                    <DefaultButton disabled={currentImage === 0} onClick={this.prev}> &lt; Previous</DefaultButton>
                    <div>{currentImage+1} of {images.length}</div>
                    <DefaultButton disabled={currentImage >= images.length-1} onClick={this.next}> &gt; Next</DefaultButton>
                </div>
            </div>
            <hr />

        </div>;

    }
}